import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import AppState from './AppState'
import Alert from 'react-s-alert';
import i18nInner from './i18n';

// import Login from './views/Login/';
// import AffiliatesWelcome from './views/AffiliatesWelcome/';
// import Register from './views/Register/';
// import ForgotPassword from './views/ForgotPassword/';
// import Logout from './views/Logout/';
// import SignUpWizard from './views/SignUpWizard/';
// import NotFound from './views/NotFound/';

// Convert direct imports to lazy loading (same order as original)
const Login = React.lazy(() => import('./views/Login/'));
const AffiliatesWelcome = React.lazy(() => import('./views/AffiliatesWelcome/'));
const Register = React.lazy(() => import('./views/Register/'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/'));
const Logout = React.lazy(() => import('./views/Logout/'));
const SignUpWizard = React.lazy(() => import('./views/SignUpWizard/'));
const NotFound = React.lazy(() => import('./views/NotFound/'));

import api from './api'
import helpers from './helpers';
import { Helmet } from 'react-helmet';

// Styles

import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import '../scss/style.scss'
import '../scss/core/_dropdown-menu-right.scss';
import '../scss/react-select/default.scss';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import "react-toggle/style.css";
import 'icheck/skins/all.css';
import "react-big-calendar/lib/css/react-big-calendar.css";
import 'rc-time-picker/assets/index.css';
import 'react-phone-number-input/style.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Containers
// import Dashboard from './containers/Dashboard/'
// import PaymentRequestSuccessPage from './pages/PaymentRequestSuccessPage/'
// import UserPageExternal from './pages/UserPageExternal/'
// import HealthCareDeclarationExternal from './pages/HealthCareDeclarationExternal/'
// import AffiliatesExternal from './pages/AffiliatesExternal/'
// import StudioCalendarExternal from './pages/StudioCalendarExternal/'
// import ApplePayPaymentPageExternal from './pages/ApplePayPaymentPageExternal/'
// import StudioTermsExternal from './pages/StudioTermsExternal/'
// import StudioPaymentPageExternal from './pages/StudioPaymentPageExternal/'
// import CoronaDeclarationFormExternal from './pages/CoronaDeclarationFormExternal/'
// import RegisterStudent from './pages/RegisterStudent/'

// Containers and Pages (same order as original)
const Dashboard = React.lazy(() => import('./containers/Dashboard/'));
const PaymentRequestSuccessPage = React.lazy(() => import('./pages/PaymentRequestSuccessPage/'));
const UserPageExternal = React.lazy(() => import('./pages/UserPageExternal/'));
const HealthCareDeclarationExternal = React.lazy(() => import('./pages/HealthCareDeclarationExternal/'));
const AffiliatesExternal = React.lazy(() => import('./pages/AffiliatesExternal/'));
const StudioCalendarExternal = React.lazy(() => import('./pages/StudioCalendarExternal/'));
const ApplePayPaymentPageExternal = React.lazy(() => import('./pages/ApplePayPaymentPageExternal/'));
const StudioTermsExternal = React.lazy(() => import('./pages/StudioTermsExternal/'));
const StudioPaymentPageExternal = React.lazy(() => import('./pages/StudioPaymentPageExternal/'));
const CoronaDeclarationFormExternal = React.lazy(() => import('./pages/CoronaDeclarationFormExternal/'));
const RegisterStudent = React.lazy(() => import('./pages/RegisterStudent/'));
import { log } from './api/Log';
import LoadingFallback from './components/LoadingFallback/LoadingFallback';

log('AppState', AppState);
log('User Exists', Parse.User.current());
log('I18n._localeKey', I18n._localeKey);

api.setRemoteConfigs();

helpers();
ReactDOMRender()

function ReactDOMRender() {
	return ReactDOM.render((
		<div>
			<Helmet>
				<title>{showTextByBusinessType('common.DASHBOARD_TITLE')}</title>
				<meta name='author' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
				<meta name='twitter:site' content={showTextByBusinessType('common.DASHBOARD_URL')} />
				<meta name='twitter:creator' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
				<meta name='twitter:title' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
				<meta name='twitter:image' content={'public/img/favicon.png'} />
				<meta property='og:title' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
				<meta property='og:site_name' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
				<meta property='og:type' content={"website"} />
				<meta property='og:url' content={showTextByBusinessType('common.DASHBOARD_URL')} />
				<meta property='og:description' content={showTextByBusinessType('common.DASHBOARD_DESCRIPTION')} />
				<meta property='og:image' content={'public/img/favicon.png'} />
				<meta property='og:site_name' content={showTextByBusinessType('common.DASHBOARD_TITLE')} />
			</Helmet>
			<HashRouter>
				<Suspense fallback={<LoadingFallback />}>
					<Switch>
						<Route path="/pages/students/:teacherId/:external?/:affiliates?" name="RegisterStudent" component={RegisterStudent} />

						<Route path="/login/:locale?/:affiliates?" name="Login" component={Login} />
						<Route path="/register/:teacherId?/:locale?" name="Register" component={Register} />
						<Route path="/affiliates/:locale?" name="AffiliatesWelcome" component={AffiliatesWelcome} />

						<Route path="/forgot-password" name="ForgotPassword" component={ForgotPassword} />
						<Route path="/logout" name="Logout" component={Logout} />
						<Route path="/signup-wizard" name="SignUpWizard" component={SignUpWizard} />
						<Route path="/not-found" name="NotFound" component={NotFound} />

						<Route path="/pages/widgets/calendar/:teacherId/:locale" name="StudioCalendarExternal" component={StudioCalendarExternal} />

						<Route path="/pages/users/:userId/:teacherId/:isStudioTeacher" name="UserPageExternal" component={UserPageExternal} />
						<Route path="/pages/forms/health/:userId/:teacherId/:locale?" name="HealthCareDeclarationExternal" component={HealthCareDeclarationExternal} />
						<Route path="/pages/forms/terms/:userId/:teacherId/:locale?" name="StudioTermsExternal" component={StudioTermsExternal} />
						<Route path="/pages/affiliates/:teacherId/:locale?/:loginUser?" name="AffiliatesExternal" component={AffiliatesExternal} />

						<Route path="/pages/forms/corona/:userId/:teacherId/:sessionId/:locale" name="CoronaDeclarationFormExternal" component={CoronaDeclarationFormExternal} />

						<Route path="/pages/payments/view/apple/:userId/:teacherId/:saleId/:locale?" name="ApplePayPaymentPageExternal" component={ApplePayPaymentPageExternal} />
						<Route path="/pages/payments/view/:teacherId/:className/:objectId/:locale?" name="StudioPaymentPageExternal" component={StudioPaymentPageExternal} />
						<Route path="/pages/payment-request/success/:response?/:cField1?/:cField2?" name="PaymentRequestSuccessPage" component={PaymentRequestSuccessPage} />

						<Route path="/dashboard" name="Dashboard" component={Dashboard} />
						<Route path="/dashboard/profile" name="Profile" component={Dashboard} />
						<Route path="/dashboard/students" name="Students" component={Dashboard} />
						<Route path="/dashboard/profile/messages" name="Messages" component={Dashboard} />
						<Route path="/dashboard/users/:userId/:teacherId/:isStudioTeacher" name="UserPage" component={Dashboard} />
						<Route path="/dashboard/terms/:teacherId" name="StudioTermsExternal" component={Dashboard} />
						<Route path="/dashboard/payments/view/:teacherId/:className/:objectId" name="StudioPaymentPageExternal" component={Dashboard} />

						<Route path="/dashboard/plans" name="Plans" component={Dashboard} />
						<Route path="/dashboard/plans/:planType/:planId/edit" name="TicketEdit" component={Dashboard} />

						<Route path="/dashboard/coupons" name="Coupons" component={Dashboard} />
						<Route path="/dashboard/coupons/:objectId?/edit" name="CouponEdit" component={Dashboard} />

						<Route path="/dashboard/sessions/:sessionId/edit" name="SessionEdit" component={Dashboard} />

						<Route path="/dashboard/billing" name="Billing" component={Dashboard} />
						<Route path="/dashboard/billing-setup" name="BillingRegister" component={Dashboard} />
						<Route path="/dashboard/affiliates" name="Affiliates" component={Dashboard} />


						<Route path="/dashboard/products/all" name="Products" component={Dashboard} />
						<Route path="/dashboard/products/:productId/edit" name="ProductEdit" component={Dashboard} />

						<Redirect from="/dashboard/plans" to="/dashboard/plans/all" />
						<Redirect from="/dashboard/products" to="/dashboard/products/all" />
						<Redirect from="/dashboard/coupons" to="/dashboard/coupons/all" />

						<Redirect from="/dashboard/messages" to="/dashboard/messages/private-chats" />

						<Route path="/dashboard/tutorials" name="Tutorials" component={Dashboard} />

						<Route path="/dashboard/contact" name="Contact" component={Dashboard} />

						{(AppState.get('isLoggedIn') && Parse.User.current()) &&
							<Redirect from="/" to="/dashboard" />
						}

						{(!AppState.get('isLoggedIn') || !Parse.User.current()) &&
							<Redirect from="/" to="/login" />
						}

						{/* {AppState.get('isLoggedIn') && !Parse.User.current() &&
						<Redirect from="/" to="/login" />
					}

					{!AppState.get('isLoggedIn') && !isParseUserSessionTokenValid &&
						<Redirect from="/" to="/login" />
					}

					{!AppState.get('isLoggedIn') &&
						<Redirect from="/" to="/login" />
					} */}
					</Switch>
				</Suspense>

			</HashRouter>
			<Alert stack={{ limit: 3 }} effect={'slide'} position={'top-' + (I18n._localeKey === 'he' ? 'left' : 'right')} />
		</div>
	), document.getElementById('root'));
}